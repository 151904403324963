import React, { useState } from "react"
import styled from "styled-components";

import { withPrefix } from "gatsby"

import FeaturedHeader from "components/Blog/FeaturedHeader"
import { FlexParent } from "components/Layouts/Utils"
import { MainContentMaxWidth, SmallMaxWidth } from "components/Layouts/MaxWidth"
import { StyledStepContainerDialog } from "./DialogWithSteps"
import Dialog from "components/Layouts/Dialog"

import { H2, P, Sub } from "components/Typography"

const StyledInlineStep = styled.div`
    padding: 1rem;
    width: 50%;

    p a:link {
        color: var(--lilac);
    }

    @media (max-width: 768px) {
        width: 100%;
        padding: 0.5rem;
    }
`
const StyledStepContainer = styled.div`
    background-color: #211045;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button {
        text-align:left;
    }

    section {
        padding: 3rem;
        padding-bottom: 0;
        z-index: 2;
        position: relative;
    }

    .oversized {
        position: absolute;
        top: 0rem;
        line-height: 1;
        left: 1rem;
        z-index: 1;
        opacity: 0.1;
    }

    @media (max-width: 768px) {
        section {
            padding: 2rem 2rem;
            padding-bottom: 0rem;
        }
    }
`

const Video = styled.video`
    width: 100%;
`

const InlineStep = ({
    subtitle, title, textHTML, video, i, openWhatsAWallet = null, openMetaMask = null, openExisting = null,
    includeAlternateSteps = false,
    includeWhatsAWallet = false
}) => {

    return (
        <StyledInlineStep>
            <StyledStepContainer>
                <section>
                    <div className="oversized">
                        <H2 size="6.5em">{i}</H2>
                    </div>
                    <Sub style={{ textTransform: "uppercase" }}>{subtitle}</Sub>
                    <H2 size="2.2rem" color="white">{title}</H2>
                    {includeAlternateSteps && <P>
                        Once you’ve created your Sequence Wallet, it’s time to create your Skyweaver account to enter the game! Choose a username and enter your email address. Click ENTER, and you’re in!
                        <br />
                        <br />
                        Already have a Skyweaver account? Check out  <button className="color-purple" onClick={openExisting}>these steps.</button>
                        <br />
                        Used Metamask? Check <button className="color-purple" onClick={openMetaMask}>these steps.</button>
                    </P>}

                    {includeWhatsAWallet ? <P>
                        Click “New Account”. Before creating your Skyweaver account, you need to create a wallet, so that you can store the cards you collect!
                        <br />
                        <br />
                        Create our Sequence Wallet by signing up with your email address, or log in using a familiar service of your choice to create your wallet.
                        <br />
                        <button className="color-purple" onClick={openWhatsAWallet}>* What's a wallet?</button>
                    </P> : <P><span dangerouslySetInnerHTML={{ __html: textHTML }} /></P>}
                </section>

                <Video src={withPrefix(video.url)} poster={video.placeholder} playsInline autoPlay loop muted />
            </StyledStepContainer>
        </StyledInlineStep>
    )
}


const Landing = ({
    getStarted,
    openSignup,
    openWhatsAWallet
}) => {
    const [metaMaskIsOpen, setMetaMaskOpen] = useState(false)
    const [existingIsOpen, setExistingOpen] = useState(false)


    const openMetaMask = () => setMetaMaskOpen(true)
    const closeMetaMask = () => setMetaMaskOpen(false)


    const openExisting = () => setExistingOpen(true)
    const closeExisting = () => setExistingOpen(false)

    return (
        <>
            <FeaturedHeader>{getStarted.title}</FeaturedHeader>
            <SmallMaxWidth style={{
                textAlign: "center"
            }}>
                {/* <P>{getStarted.text}</P> */}
                {/* <div>
                    <PlayFreeButton />
                </div> */}
            </SmallMaxWidth>
            <MainContentMaxWidth style={{
                paddingLeft: "1rem",
                paddingRight: "1rem"
            }}>
                <FlexParent alignItems="stretch">
                    {getStarted.steps.map((step, i) => <InlineStep
                        openWhatsAWallet={openWhatsAWallet}
                        openExisting={openExisting}
                        openMetaMask={openMetaMask} key={i} {...step} i={i + 1} />)}
                </FlexParent>
            </MainContentMaxWidth>

            <Dialog
                showDialog={metaMaskIsOpen}
                close={closeMetaMask}
                open={openMetaMask}
            >
                <video src="https://s3.amazonaws.com/skyweaver.net/video/get-started-metamask/3.mp4" autoPlay muted loop></video>
                <StyledStepContainerDialog>
                    <section>
                        <H2 size="2.2rem" color="white">
                            MIGRATE AN EXISTING SKYWEAVER ACCOUNT USING METAMASK
                        </H2>
                        <P>Type in your username and click “Migrate with Metamask” and follow the steps to connect with Skyweaver.</P>
                    </section>
                </StyledStepContainerDialog>
            </Dialog>

            <Dialog
                showDialog={existingIsOpen}
                close={closeExisting}
                open={openExisting}
            >
                <video src="https://s3.amazonaws.com/skyweaver.net/video/get-started-existing/3.mp4" autoPlay muted loop></video>
                <StyledStepContainerDialog>
                    <section>
                        <H2 size="2.2rem" color="white">
                            LINK AN EXISTING SKYWEAVER ACCOUNT
                        </H2>
                        <P>Type in your username and the password associated with that Skyweaver account. Click ENTER. </P>
                    </section>
                </StyledStepContainerDialog>
            </Dialog>
        </>
    )
}

export default Landing