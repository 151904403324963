import React from "react"
import styled from "styled-components";


import { MainContentMaxWidth, MedMaxWidth } from "components/Layouts/MaxWidth"
import FeaturedHeader from "components/Blog/FeaturedHeader"


import { H2, P } from "components/Typography"
import { BackgroundImage } from "components/Layouts/Utils"

const StyledSection = styled.section`
    padding: 6rem 0;
    position: relative;

    section {
        text-align: center;
        position: relative;
        z-index: 2;
    }
    
    @media (max-width: 768px) {
        section {
            text-align: left;
        }
    }
`


const StyledBlockSectionParent = styled.div`
    position: relative;
    width: 100%;
`
const StyledBlockSection = styled.div`
    width: 53.5%;

    img {
        width: 100%;
    }

    &:first-of-type {
        position: relative;
    }

    &:last-of-type {
        width: 53.5%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0rem;
    }
    .text, .mobile-img {display: none}

    @media (max-width: 768px) {
        width: 100%;
        position: relative;
        max-width: 30rem;
        margin: auto;

        img {display: none}
        img.mobile-img{display:block;}

        .text {
            display: block;
            position: absolute;
            text-align: center;
            top: 50%;left: 50%;
            width: 100%;
            padding: 2rem;
            max-width: 30rem;
            transform: translate(-50%,-50%);
            z-index: 3;
            margin-top: -8%;
            
        }
        &:last-of-type {
            margin-top:-20%;
            width: 100%;
            position: relative;
            top: 0%;
            transform: none;
            right: 0rem;

            .text {
                margin-top: 5%;
            }
        }
    }
`

const StyledTextSection = styled.div`
    position: absolute;
    width: 50%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    text-align: center;

    div {
        max-width: 20rem;
        margin: auto;
        texta-lign: center;
    }

    &:last-of-type {
      right: 0;
    }


    &:first-of-type {
        left: 0;
    }

    @media (max-width: 768px) {
        display: none;
    }
`

const Marketplace = ({
    title, secondaryTitle, text, img, content, cardImage, openWhatsAWallet, subtitle
}) => {
    return (
        <StyledSection>
            <BackgroundImage loading="lazy" src={img.url} />

            <section>
                <FeaturedHeader>
                    Trade cards you own on the <button
                        onClick={openWhatsAWallet}
                        style={{
                            textDecoration: "underline",
                            textTransform: "uppercase"
                        }}>Market</button>
                </FeaturedHeader>

                <MedMaxWidth style={{
                    marginBottom: "2rem",
                    marginTop: "2rem"
                }}>
                    <img alt="" style={{
                        width: "100%"
                    }} src={cardImage.url} loading="lazy" />
                </MedMaxWidth>

                <MedMaxWidth style={{
                    paddingTop: "1rem",
                    marginBottom: "4rem"
                }}>

                    <H2 color="white" style={{
                        fontSize: "2.2rem"
                    }}>{secondaryTitle}</H2>
                    <P style={{ fontStyle: "italic" }}>{subtitle}</P>
                </MedMaxWidth>
            </section>

            <MainContentMaxWidth>
                <StyledBlockSectionParent>
                    {content.map((block, i) =>
                        <StyledBlockSection key={`img-${i}-${block.title}`}>
                            <img className="desktop-img" loading="lazy" src={block.img.url} alt="" />
                            <img className="mobile-img" src={block.mobile_img.url} alt="" loading="lazy" />
                            <div className="text">
                                <H2 color="white">{block.title}</H2>
                                <div><P>{block.text}</P></div>
                            </div>
                        </StyledBlockSection>
                    )}
                </StyledBlockSectionParent>


                {content.map((block, i) =>
                    <StyledTextSection key={`text-${i}`}>
                        <H2 color="white">{block.title}</H2>
                        <div><P>{block.text}</P></div>
                    </StyledTextSection>
                )}

            </MainContentMaxWidth>

        </StyledSection>
    )
}

export default Marketplace