import React from "react"

import { MainContentMaxWidth, SmallMaxWidth } from "components/Layouts/MaxWidth"
import { P } from "components/Typography"
import { FlexParent } from "components/Layouts/Utils"
import FeaturedHeader from "components/Blog/FeaturedHeader"

import { ClippedCard } from "./GameModes"

const AllGameModesInclude = ({
    text, title, content
}) => {

    return (
        <>
            <FeaturedHeader>{title}</FeaturedHeader>
            <SmallMaxWidth style={{
                textAlign: "center",
                marginBottom: "1rem"
            }}>
                <P>{text}</P>
            </SmallMaxWidth>

            <MainContentMaxWidth>
                <FlexParent>
                    {content.map((card, i) => <ClippedCard displayPopupForMobile key={i} {...card} />)}
                </FlexParent>
            </MainContentMaxWidth>
        </>
    )
}

export default AllGameModesInclude