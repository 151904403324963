import React, { useState } from "react"
import styled from "styled-components";


import Dialog from "components/Layouts/Dialog"

import { MainContentMaxWidth } from "components/Layouts/MaxWidth"
import FeaturedHeader from "components/Blog/FeaturedHeader"
import { H2, H3, P, CheckMark } from "components/Typography"
import { FlexParent } from "components/Layouts/Utils"
import Button from "components/Button"


const StyledClippedCard = styled.div`
    width: ${({ isLarge }) => isLarge ? "50%" : "33.33%"};
    padding: 1rem;
    position: relative;

    img {
        width: 100%;
    }
    
    section {
        position: Relative;
        width: 100%;
        
        display: flex;
        overflow: hidden;
        position: relative;
    }

    .text {
        position: absolute;
        padding: 2rem;
        padding-right: 3rem;

        min-height: 50%;
        bottom: 0rem;
        left: 1rem;
        z-index: 3;
    }

    .display-on-mobile {
        display: none;
    }

    @media (max-width: 768px) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;

        .text {
            min-height: unset;
            padding-bottom: 2.5rem;
        }

        .display-on-mobile {
            display: block;
        }
        .display-on-desktop {display:none}

        .text-outside-popup {
            display: none;
        }
    }
`

export const ConquestClippedCard = ({
    img,
    title,
    textHTML,
    points = [],
    isLarge = false,
    subtitle = false,
    secondaryImg = false,
    mobileText,
    openSilver,
    openDai
}) => {
    const [isOpen, setOpen] = useState(false)

    const open = () => setOpen(true)
    const close = () => setOpen(false)

    return (
        <>
            <StyledClippedCard isLarge={isLarge}>
                <section>
                    <img alt="" className="display-on-desktop" loading="lazy" src={img.url} />
                    <img alt="" className=" display-on-mobile" loading="lazy" src={img.mobile && img.mobile.url ? img.mobile.url : img.url} />
                    <div className="text text-outside-popup ">
                        <H2 style={{ fontSize: "2.2rem" }} color="white">{title}</H2>
                        <P><span dangerouslySetInnerHTML={{ __html: textHTML }} /></P>
                        {points.map((point, i) => i === 1 ? <span key={i}>
                            <CheckMark style={{
                                textTransform: "unset"
                            }} key={point}>
                                <span style={{
                                    textTransform: "uppercase"
                                }}>{point}</span> <br />
                                <span style={{
                                    paddingLeft: "1.5rem",
                                    fontSize: "1rem",
                                    marginTop: "0.5rem",
                                }}>
                                    <img alt="Conquest Ticket" style={{
                                        width: "30px",
                                        marginRight: "5px",
                                        // verticalAlign: "top"
                                    }} src={"/images/community/get-started/conquest-ticket.png"} />
                                    Get a Conquest ticket by <button onClick={openSilver} style={{ cursor: "pointer", textDecoration: "underline" }}>trading either 1 Silver card</button> or <button onClick={openDai} style={{ cursor: "pointer", textDecoration: "underline" }}>1.5 USDC</button> for it</span>
                            </CheckMark>
                        </span> : <CheckMark key={i}>{point}</CheckMark>)}

                        {secondaryImg && <img alt="" style={{
                            margin: "1rem auto"

                        }} loading="lazy" src={secondaryImg.url} />}
                        {subtitle && <H3
                            style={{
                                textTransform: "unset",
                                fontSize: "0.9rem",
                                marginBottom: "2rem"
                            }} color="white"
                        >{subtitle}</H3>}
                    </div>
                </section>

                <>
                    <div className="text display-on-mobile">
                        <H2 style={{ fontSize: "2.2rem" }} color="white">{title}</H2>
                        {mobileText && <P>{mobileText}</P>}
                        <div style={{
                            marginBottom: "2.5rem"
                        }}>
                            <Button color="var(--bg)" onClick={open}>Learn More</Button>
                        </div>

                    </div>
                    <Dialog
                        showDialog={isOpen}
                        open={open}
                        close={close}
                    >
                        <div style={{
                            padding: "1.5rem"
                        }}>
                            <H2 style={{ fontSize: "2.2rem" }} color="white">{title}</H2>
                            <P ><span dangerouslySetInnerHTML={{ __html: textHTML }} /></P>
                            {points.map((point, i) => i === 1 ? <div key={i}>
                                <CheckMark style={{
                                    textTransform: "unset"
                                }} key={point}>
                                    <span style={{
                                        textTransform: "uppercase"
                                    }}>{point}</span> <br />
                                    <div style={{
                                        paddingLeft: "1.5rem",
                                        fontSize: "1rem",
                                        marginTop: "0.5rem",
                                    }}>
                                        <img alt="" style={{
                                            width: "30px",
                                            marginRight: "5px",
                                            // verticalAlign: "top"
                                        }} src={"/images/community/get-started/conquest-ticket.png"} />
                                        Get a Conquest ticket by <button onClick={openSilver} style={{ cursor: "pointer", textDecoration: "underline" }}>trading either 1 Silver card</button> or <button onClick={openDai} style={{ cursor: "pointer", textDecoration: "underline" }}>1.5 USDC</button> for it</div>
                                </CheckMark>
                            </div> : <CheckMark key={point}>{point}</CheckMark>)}

                            {secondaryImg && <img alt="" style={{
                                margin: "1rem auto"

                            }} loading="lazy" src={secondaryImg.url} />}
                            {subtitle && <H3
                                style={{
                                    textTransform: "unset",
                                    fontSize: "0.9rem",
                                    marginBottom: "2rem"
                                }} color="white"
                            >{subtitle}</H3>}
                        </div>

                    </Dialog>
                </>

            </StyledClippedCard>
        </>
    )
}

export const ClippedCard = ({
    img,
    title,
    textHTML,
    points = [],
    displayPopupForMobile = false,
    mobileText,
    isLarge = false,
    subtitle = false,
    secondaryImg = false
}) => {
    const [isOpen, setOpen] = useState(false)

    const open = () => setOpen(true)
    const close = () => setOpen(false)

    return (
        <>
            <StyledClippedCard isLarge={isLarge}>
                <section>
                    <img alt="" className="display-on-desktop" loading="lazy" src={img.url} />
                    <img alt="" className="display-on-mobile" loading="lazy" src={img.mobile && img.mobile.url ? img.mobile.url : img.url} />
                    <div className={`text ${displayPopupForMobile && "text-outside-popup"}`}>
                        <H2 style={{ fontSize: "2.2rem" }} color="white">{title}</H2>
                        <P style={{
                            fontSize: "1.1rem"
                        }}><span dangerouslySetInnerHTML={{ __html: textHTML }} /></P>
                        {points.map((point, i) => <CheckMark key={point}>{point}</CheckMark>)}

                        {secondaryImg && <img alt="" style={{
                            margin: "1rem auto"
                        }} loading="lazy" src={secondaryImg.url} />}
                        {subtitle && <H3
                            style={{
                                // textTransform: "uppercase",
                                fontSize: "0.9rem",
                                textAlign: "center",
                                padding: "0.5rem",
                                paddingBottom: "1rem"
                            }} color="white"
                        >{subtitle}</H3>}
                    </div>

                    {displayPopupForMobile &&
                        <>
                            <div className="text display-on-mobile">
                                <H2 style={{ fontSize: "2.2rem" }} color="white">{title}</H2>
                                {mobileText && <P>{mobileText}</P>}
                                <div style={{
                                    marginBottom: "2rem"
                                }}>
                                    <Button color="var(--bg)" onClick={open}>Learn More</Button>
                                </div>
                            </div>
                            <Dialog
                                showDialog={isOpen}
                                open={open}
                                close={close}
                            >
                                <div style={{
                                    padding: "1.5rem"
                                }}>
                                    <H2 style={{ fontSize: "2.2rem" }} color="white">{title}</H2>
                                    <P><span dangerouslySetInnerHTML={{ __html: textHTML }} /></P>
                                    {points.map((point, i) => <CheckMark key={point}>{point}</CheckMark>)}

                                    {secondaryImg && <img alt="" style={{
                                        margin: "1rem auto"
                                    }} loading="lazy" src={secondaryImg.url} />}
                                    {subtitle && <H3
                                        style={{
                                            // textTransform: "uppercase",
                                            fontSize: "0.9rem",
                                            textAlign: "center",
                                            padding: "0.5rem",
                                            paddingBottom: "1rem"
                                        }} color="white"
                                    >{subtitle}</H3>}
                                </div>

                            </Dialog>
                        </>
                    }
                </section>

            </StyledClippedCard>
        </>
    )
}

const GameModes = (props) => {
    const {
        title, content, tradeSilverCardVideo, tradeDaiVideo,
    } = props

    const [daiIsOpen, setDaiOpen] = useState(false)
    const [silverIsOpen, setSilverOpen] = useState(false)

    const openDai = () => setDaiOpen(true)
    const closeDai = () => setDaiOpen(false)
    const openSilver = () => setSilverOpen(true)
    const closeSilver = () => setSilverOpen(false)

    return (
        <>
            <FeaturedHeader>{title}</FeaturedHeader>

            <MainContentMaxWidth style={{
                marginBottom: "6rem"
            }}>
                <FlexParent>
                    {content.map((card, i) => card.title === "Conquest" ? <ConquestClippedCard displayPopupForMobile openSilver={openSilver} openDai={openDai} key={i + card.title} {...card} /> : <ClippedCard
                        displayPopupForMobile={i === 0}

                        key={i} {...card} />)}
                </FlexParent>
            </MainContentMaxWidth>

            <Dialog
                showDialog={silverIsOpen}
                open={openSilver}
                close={closeSilver}
            >
                <video src={tradeSilverCardVideo.url} autoPlay muted loop />
            </Dialog>

            <Dialog
                showDialog={daiIsOpen}
                open={openDai}
                close={closeDai}
            >
                <video src={tradeDaiVideo.url} autoPlay muted loop />
            </Dialog>
        </>
    )
}

export default GameModes