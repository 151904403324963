import React, { useState, useRef } from "react"
import styled from "styled-components";
import Slider from "react-slick";

import FeaturedHeader from "components/Blog/FeaturedHeader"

import { LargeMaxWidth } from "components/Layouts/MaxWidth"
import { Tracker } from "components/SectionGameplay/GameplaySlideshow"

import { H2, P } from "components/Typography"
import { FlexParent } from "components/Layouts/Utils"

const StyledCarousel = styled.div`
    width: 35%;
    border-radius: 1.56rem;
    overflow: hidden;


    @media (max-width: 768px) {
        max-width: 20rem;
        width: 100%;
        margin-bottom: 2rem;
    }
`

const StyledText = styled.div`
    width: 65%;
    padding-left: 3rem;
    padding-right: 2rem;

    @media (max-width: 768px) {
        width: 100%;
        padding: 1rem;
        
    }
`

const CarouselItem = styled.div`
    padding: 0rem 1.5rem;
    img {width: 100%;}
`

const Icons = styled.div`
    margin-top: 2rem;

    a {
        display: inline-block;
    }

    img {
        width: 5rem;
        z-index: 3;
        position: relative;
    }

    img:last-of-type {
        z-index: -1;
        margin-left: -1rem;
    }

    
    @media (max-width: 768px) {
        margin: auto;
        text-align: center;
    }
`

const SequenceWallet = ({
    subtitle, title, textHTML, carousel, openWhatsAWallet
}) => {
    const [selectedI, setI] = useState(0)
    const swiperRef = useRef(null)

    const slideShowOptions = {
        infinite: true,
        centerPadding: "200px",
        autoplay: {
            delay: 4000
        },
        beforeChange: (_, next) => setI(next)
    }

    const goTo = i => {
        swiperRef.current.slickGoTo(i)
    }

    return (
        <>
            <div style={{
                padding: "5rem 0",
                paddingBottom: "8rem",
                backgroundColor: "#141128",
                marginBottom: "8rem"
            }}>
                <FeaturedHeader>
                    YOUR SEQUENCE WALLET STORES YOUR CARDS <br /> & CONNECTS YOU TO THE <button
                        onClick={openWhatsAWallet}
                        style={{
                            textDecoration: "underline",
                            textTransform: "uppercase"
                        }}>Market</button>
                </FeaturedHeader>
                <LargeMaxWidth style={{
                    marginTop: "4rem",
                }}>
                    <FlexParent>
                        <StyledCarousel>
                            <Slider ref={swiperRef}  {...slideShowOptions} >
                                {carousel.map((step, i) => <CarouselItem key={i}><img alt="" loading="lazy" src={step.img.url} /></CarouselItem>)}
                            </Slider>
                            <Tracker goTo={goTo} elements={carousel} selectedIndex={selectedI} />
                        </StyledCarousel>
                        <StyledText>
                            <H2 color="white" style={{
                                fontSize: "2.2rem"
                            }}>{title}</H2>
                            <P><span dangerouslySetInnerHTML={{ __html: textHTML }} /></P>
                            <Icons>
                                <a href="https://play.skyweaver.net" target="_blank" rel="noopener noreferrer">
                                    <img src="/images/community/get-started/skyweaver-logo.png" alt="Skyweaver Logo" />
                                </a>
                                <a href="https://sequence.info" target="_blank" rel="noopener noreferrer">
                                    <img src="/images/community/get-started/sequence-logo.png" alt="Sequence Logo" />
                                </a>

                            </Icons>
                        </StyledText>
                    </FlexParent>
                </LargeMaxWidth >
            </div>
        </>
    )
}

export default SequenceWallet