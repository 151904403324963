import React, { useState } from "react"
import styled from "styled-components";

import Layout from "components/Blog/Layout"

import Landing from "./Landing"
import ExistingPlayers from "./ExistingPlayers"
import GameModes from "./GameModes"
import AllGameModesInclude from "./AllGameModesInclude"
import Marketplace from "./Marketplace"
import SequenceWallet from "./SequenceWallet"
import YourAssets from "./YourAssets"
import TradeBenefits from "./TradeBenefits"

import Form from "components/SectionEarlyAccess/Form"
import { H2, P } from "components/Typography"
import { SmallMaxWidth } from "components/Layouts/MaxWidth"
import Dialog from "components/Layouts/Dialog"

const StyledForm = styled.div`
    padding: 4rem;
    text-align: center;

    .early-access-form {
        padding: 0;
    }
`


const GetStarted = ({
    getStarted, metaMaskFlow, existingUserFlow, gameModes, allGameModesInclude, marketplace, sequenceWallet, yourAssets, tradeBenefits, sectionEarlyAccess
}) => {
    const [signupIsOpen, setSignupOpen] = useState(false)
    const [whatsAWalletIsOpen, setWhatsAWallet] = useState(false)
    const [whatsAMarketIsOpen, setWhatsAMarket] = useState(false)

    const openSignup = () => setSignupOpen(true)
    const closeSignup = () => setSignupOpen(false)
    const openWhatsAWallet = () => setWhatsAWallet(true)
    const closeWhatsAWallet = () => setWhatsAWallet(false)

    const openWhatsAMarket = () => setWhatsAMarket(true)
    const closeWhatsAMarket = () => setWhatsAMarket(false)

    return (
        <Layout>
            <Landing openWhatsAWallet={openWhatsAWallet} openSignup={openSignup} sectionEarlyAccess={sectionEarlyAccess} getStarted={getStarted} metaMaskFlow={metaMaskFlow} />
            <ExistingPlayers {...existingUserFlow} />
            <GameModes {...gameModes} />
            <AllGameModesInclude {...allGameModesInclude} />
            <Marketplace openWhatsAWallet={openWhatsAMarket} {...marketplace} />
            <SequenceWallet openWhatsAWallet={openWhatsAMarket}  {...sequenceWallet} />
            <YourAssets {...yourAssets} />
            <TradeBenefits buttonText={getStarted.buttonText} openSignup={openSignup} {...tradeBenefits} />

            <Dialog
                showDialog={whatsAWalletIsOpen}
                close={closeWhatsAWallet}
                open={openWhatsAWallet}
            >
                <TextContainer>
                    <H2 style={{
                        color: "white",
                        fontSize: "2rem"
                    }}>What's a wallet?</H2>
                    <P>
                        {/* For those familiar with Ethereum and Web3, the Skyweaver Market will be powered by Niftyswap and <span style={{
                            color: `var(--lilac)`
                        }}>all Skyweaver players will be given a Sequence wallet to transact in the Market!</span> The Sequence wallet is a non-custodial multi-chain Smart Wallet perfect for coins, NFTs, game items and other digital goods! */}
                        The Sequence Wallet is a friendly and secure Smart Wallet that’s perfect for Skyweaver cards, cryptocurrencies, NFTs, Web3, the metaverse, game items and other digital goods! The wallet will let you trade cards on the Skyweaver Market as well.
                    </P>
                </TextContainer>
            </Dialog>


            <Dialog
                showDialog={whatsAMarketIsOpen}
                close={closeWhatsAMarket}
                open={openWhatsAMarket}
            >
                <TextContainer>
                    <H2 style={{
                        color: "white",
                        fontSize: "2rem"
                    }}>What's the Market?</H2>
                    <P>
                        The Skyweaver Market is where players can buy and sell their tradable Skyweaver cards. Built atop the Niftyswap protocol and part of the Ethereum ecosystem, the Skyweaver Market is a peer-to-peer market that ensures efficient pricing and ease of use. All Skyweaver players are given a Sequence Wallet to transact in the Market!</P>
                    <P>
                        The Sequence Wallet is a friendly and secure Smart Wallet that’s perfect for Skyweaver cards, cryptocurrencies, NFTs, Web3, the metaverse, game items and other digital goods!
                    </P>
                </TextContainer>
            </Dialog>

            <Dialog
                showDialog={signupIsOpen}
                close={closeSignup}
                open={openSignup}
            >
                <StyledForm>
                    <SmallMaxWidth>
                        <H2 color='#fff' uppercase>{sectionEarlyAccess.title}</H2>
                        <P>
                            <span dangerouslySetInnerHTML={{ __html: sectionEarlyAccess.subtitle }} />
                        </P>
                    </SmallMaxWidth>
                    <Form buttonColor="var(--blue)" />
                </StyledForm>
            </Dialog>
        </Layout>

    )
}

const TextContainer = styled.div`
    max-width: 52rem;
    margin: auto;
    padding: 4rem;

    @media (max-width: 768px) {
        padding: 2rem;
    }
`

export default GetStarted