import React, { useState } from "react"
import styled from "styled-components";


import { MainContentMaxWidth, SmallMaxWidth } from "components/Layouts/MaxWidth"
import Dialog from "components/Layouts/Dialog"
import { StyledStepContainerDialog } from "./DialogWithSteps"

import { H2, P, SubLarge } from "components/Typography"
import Button from "components/Button"
import { Gradient, BackgroundImage } from "components/Layouts/Utils"

const Box = styled.div`
    width: 100%;
    text-align: center;
    padding: 8rem 2rem;
    overflow: hidden;
    position: relative;
    border: 1px solid var(--lilacLowOpacity);

    @media (max-width: 768px) {
        padding: 5rem 1rem;
    }
`

const ExistingPlayers = ({
    subtitle, title, text, buttonText, img,
}) => {
    const [isOpen, setOpen] = useState(false)

    const open = () => setOpen(true)
    const close = () => setOpen(false)

    return (
        <>
            <MainContentMaxWidth style={{
                marginBottom: "6rem"
            }}>
                <Box>
                    <BackgroundImage src={img.url} />
                    <Gradient />
                    <SmallMaxWidth>
                        <SubLarge>{subtitle}</SubLarge>
                        <H2 color="white" style={{
                            fontSize: "2.2rem"
                        }}>{title}</H2>
                        <P>{text}</P>
                        <Button
                            onClick={open}
                            width="18rem" color="var(--background)" bgColor="white">
                            {buttonText}
                        </Button>
                    </SmallMaxWidth>
                </Box>
            </MainContentMaxWidth>
            <Dialog
                showDialog={isOpen}
                close={close}
                open={open}
            >
                <video src="https://s3.amazonaws.com/skyweaver.net/video/get-started-existing/3.mp4" autoPlay muted loop></video>
                <StyledStepContainerDialog>
                    <section>
                        <H2 size="2.2rem" color="white">
                            LINK AN EXISTING SKYWEAVER ACCOUNT
                        </H2>
                        <P>Type in your username and the password associated with that Skyweaver account. Click ENTER. </P>
                    </section>
                </StyledStepContainerDialog>
            </Dialog>
        </>
    )
}

export default ExistingPlayers