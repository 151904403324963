import React from "react";

import "css/main.css";
import "css/fonts.css";

import Seo from "components/SeoHead";
import GetStarted from "components/Community/GetStarted"

// title, content, footer
import data from "data/community/get-started-data"
import staticContent from 'data'

function GetStartedPage() {
	return (
		<>
			<Seo />
			<GetStarted {...staticContent} {...data} />
		</>
	)
}

export default GetStartedPage