import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components";

import { withPrefix } from "gatsby"
import Slider from "react-slick";

import { FlexParent } from "components/Layouts/Utils"
import Dialog from "components/Layouts/Dialog"
import { Tracker } from "components/SectionGameplay/GameplaySlideshow"


import { H2, P, Sub } from "components/Typography"

const Arr = styled.button`
    width: 1rem;
    height: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    background-color: var(--lilac);
    transition: 0.3s ease-out opacity;
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
`

const StyledInlineStep = styled.div`
    width: 100%;

    p a:link {
        color: var(--lilac);
    }
`
export const StyledStepContainerDialog = styled.div`
    background-color: var(--background);
    height: 100%;


    section {
        padding: 3rem;
        z-index: 2;
        position: relative;
        border-top: 1px solid var(--lilacLowOpacity);
    }

    .text {
        max-width: 46rem;
        margin: auto;
    }

    .oversized {
        position: absolute;
        top: 0rem;
        line-height: 1;
        left: 1rem;
        z-index: 1;
        opacity: 0.1;
    }


    @media (max-width: 768px) {
        section {
            padding: 2rem;
        }
    }
`

const StyledSection = styled.section`
    padding-bottom: 1rem;
`

const Video = styled.video`
    width: 100%;
`

const InlineStep = ({
    subtitle, title, textHTML, video, i, includeAlternateSteps = false
}) => {
    return (
        <StyledInlineStep>
            <StyledStepContainerDialog>
                <Video src={withPrefix(video.url)} autoPlay loop muted />
                <section>
                    <div className="text">
                        <Sub style={{ textTransform: "uppercase" }}>{subtitle}</Sub>
                        <H2 size="2.2rem" color="white">{title}</H2>
                        <P><span dangerouslySetInnerHTML={{ __html: textHTML }} /></P>
                    </div>
                </section>
            </StyledStepContainerDialog>
        </StyledInlineStep>
    )
}

const DialogWithSteps = ({
    steps, isOpen, close, open
}) => {
    const [selectedI, setI] = useState(0)
    const swiperRef = useRef(null)

    const slideShowOptions = {
        infinite: false,
        beforeChange: (current, next) => setI(next)
    }

    useEffect(() => {
        console.log(swiperRef, "RWS")
    }, [swiperRef])

    const goTo = i => {
        swiperRef.current.slickGoTo(i)
    }

    return (
        <>
            <Dialog
                small showDialog={isOpen} close={close} open={open}>
                <StyledSection>
                    <Slider ref={swiperRef}  {...slideShowOptions} >
                        {steps.map((step, i) => <InlineStep key={i} {...step} />)}
                    </Slider>


                    <FlexParent
                        justifyContent="center">
                        <Arr
                            style={{
                                opacity: selectedI === 0 ? 0.5 : 1,
                                transform: "rotate(-90deg)"
                            }}
                            onClick={() => swiperRef.current.slickPrev()}
                            className="arr prev" />
                        <Tracker goTo={goTo} elements={steps} selectedIndex={selectedI} />
                        <Arr
                            style={{
                                opacity: selectedI === steps.length - 1 ? 0.5 : 1,
                                transform: "rotate(90deg)"
                            }}
                            onClick={() => swiperRef.current.slickNext()}
                            className="arr next" />
                    </FlexParent>
                </StyledSection>

            </Dialog>
        </>
    )
}

export default DialogWithSteps