import React from "react"
import styled from "styled-components";

import FeaturedHeader from "components/Blog/FeaturedHeader"
import { FlexParent, BackgroundImage } from "components/Layouts/Utils"
import { MainContentMaxWidth, MedMaxWidth } from "components/Layouts/MaxWidth"


import { H2, P } from "components/Typography"
import PlayNowButton from "components/Header/PlayNowButton";

const StyledBlock = styled.div`
    width: 50%;
    padding: 1rem;

    @media (max-width: 768px) {
        width: 100%;
        padding: 1rem 0;
    }

    section {
        position: relative;
        height: 0;
    border: 1px solid #40326A;
        overflow: hidden;
        padding-bottom: 56%;
    }

    .text-parent {
        height: 100%;
        display: flex;
        text-align: center;
        width: 100%;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center
    }

    .text {
        padding: 2rem;
        position: absolute;
        width: 100%;
        top:50%;left: 50%;
        transform: translate(-50%,-50%);
    }
`


const Gradient = styled.div`
    position: absolute;
    top: 0;left:0;
    height: 105%;
    width: 100%;
    opacity: 0.65;
    background: linear-gradient(180deg, rgba(12, 6, 30, 0.82) 0%, #0C061E 100%)
`


const Block = ({
    title, text, img
}) => (
    <StyledBlock>
        <section>
            <BackgroundImage src={img.url} loading="lazy" />
            <Gradient />
            <div className="text-parent">

                <div className="text">
                    <H2 color="white" style={{
                        fontSize: "2.2rem"
                    }}>{title}</H2>
                    <P>{text}</P>
                </div>
            </div>

        </section>

    </StyledBlock>
)

const TradeBenefits = ({
    title, secondaryTitle, content, text, openSignup, buttonText
}) => {
    return (
        <>
            <FeaturedHeader>
                {title}
            </FeaturedHeader>
            <MedMaxWidth style={{
                textAlign: "center",
                marginTop: "3rem"
            }}>
                <H2 color="white" style={{
                    fontSize: "2.2rem"
                }}>{secondaryTitle}</H2>
                {/* <P>{text}</P> */}
                {/* <P>{text}</P> */}
                <div>
                    <PlayNowButton />
                </div>
            </MedMaxWidth>
            <MainContentMaxWidth>
                <FlexParent>
                    {content.map((block, i) => <Block {...block} key={i} />)}
                </FlexParent>
            </MainContentMaxWidth>

        </>

    )
}

export default TradeBenefits