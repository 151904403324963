import React, { useState } from "react"
import styled from "styled-components";

import FeaturedHeader from "components/Blog/FeaturedHeader"
import { MedMaxWidth, SmallMaxWidth } from "components/Layouts/MaxWidth"

import Dialog from "components/Layouts/Dialog"


import { H2, P, CheckMark } from "components/Typography"

const PointContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    // padding: 0 2rem;
    padding-bottom: 0;

    @media (max-width: 768px) {
        display: block;
    }
`

const Img = styled.img`
    width: 100%;
    margin-bottom: 6rem
`

const StyledSection = styled.div`
    text-align: center;
    @media (max-width: 768px) {
        text-align: left;
    }
`

const YourAssets = ({
    subtitle, title, text, points, img
}) => {

    const [existingIsOpen, setExistingOpen] = useState(false)

    const openExisting = () => setExistingOpen(true)
    const closeExisting = () => setExistingOpen(false)

    return (
        <StyledSection>
            <FeaturedHeader>
                {subtitle}
            </FeaturedHeader>
            <MedMaxWidth style={{
                marginBottom: 0,
                marginTop: "3rem"
            }}>
                <H2 color="white" style={{
                    fontSize: "2.2rem"
                }}>{title}</H2>
            </MedMaxWidth>
            <SmallMaxWidth>
                <P>{text}</P>
            </SmallMaxWidth>

            <MedMaxWidth style={{
                marginBottom: 0,
                marginTop: "1rem"
            }}>
                <PointContainer>
                    {points.map((point, i) =>
                        <CheckMark
                            key={i}
                        >
                            <button style={{
                                cursor: i === 1 ? "pointer" : "auto",
                                borderBottom: i === 1 ? "1px solid var(--lilac)" : "1px solid transparent",
                                paddingBottom: "0.2rem"
                            }}
                                onClick={() => {
                                    if (i === 1) openExisting()
                                }}>
                                {point}
                            </button></CheckMark>
                    )}
                </PointContainer>
            </MedMaxWidth>
            <Img src={img.url} alt="" loading="lazy" />

            <Dialog
                showDialog={existingIsOpen}
                close={closeExisting}
                open={openExisting}>
                <img alt="" src={"/images/community/get-started/yourassets.png"} />

                <TextContainer>
                    <P>
                        <span style={{
                            color: "var(--orange)"
                        }}>Horizon does not sell any cards.</span> All card grades will be created by players via some form of gameplay. Players can purchase tradable cards from and sell tradable cards to other players on the Skyweaver Market.
                    </P>
                </TextContainer>
            </Dialog>
        </StyledSection>
    )
}

const TextContainer = styled.div`
    padding: 4rem;
    max-width: 50rem;
    margin: auto;
    @media (max-width: 768px) {
        padding: 2rem;
    }
`

export default YourAssets