const data = {
    getStarted: {
        title: "Get Started",
        text: " Skyweaver is currently in Private Beta. A beta access code is needed to play the game. To get your beta access code, sign up to the beta waitlist here:",
        buttonText: "Sign up to beta waitlist",
        steps: [
            {
                subtitle: "Step One",
                title: "Open The Game",
                textHTML: `Go to <a target="_blank" rel="noopener noreferrer" href="https://play.skyweaver.net/">play.skyweaver.net</a> on desktop, or, if you’re playing on mobile, open the app on <a target="_blank" rel="noopener noreferrer" href="https://download-android.skyweaver.net/">Android</a> or <a target="_blank" rel="noopener noreferrer" href="https://download-ios.skyweaver.net/">iOS</a>. You can also download the game on <a target="_blank" rel="noopener noreferrer" href="https://download-win.skyweaver.net/">Windows</a>, <a target="_blank" rel="noopener noreferrer" href="https://download-mac.skyweaver.net/">Mac</a>, or <a target="_blank" rel="noopener noreferrer" href="https://download-linux.skyweaver.net/">Linux</a>.`,
                video: {
                    url: "https://56utils.s3.ca-central-1.amazonaws.com/openbeta-step-1.mp4",
                    placeholder: "/images/community/get-started/step1.jpg"
                }
            },
            {
                subtitle: "Step Two",
                title: "Create Your Wallet*",
                includeWhatsAWallet: true,
                // includeAlternateSteps: true,
                textHTML: null,
                video: {
                    url: "https://s3.amazonaws.com/skyweaver.net/video/get-started-new-user-updated/openbeta-step-2.mp4",
                    placeholder: "/images/community/get-started/step2.jpg"
                }
            },
            {
                subtitle: "Step Three",
                title: "Account Creation",
                includeAlternateSteps: true,
                video: {
                    url: "https://s3.amazonaws.com/skyweaver.net/video/get-started-new-user-updated/openbeta-step-3.mp4",
                    placeholder: "/images/community/get-started/step3.jpg"
                }
            },
            {
                subtitle: "All Done",
                title: "YOU’RE ALL SET! PLAY SKYWEAVER",
                textHTML: `Welcome to Sky! You’re now ready to play! Learn the game in tutorials, battle against other players in Ranked matches, enter Conquests to win Gold NFT cards, build custom decks, browse the card library, and collect and trade in the Market!`,
                video: {
                    url: "https://s3.amazonaws.com/skyweaver.net/video/get-started-new-user/4.mp4",
                    placeholder: "/images/community/get-started/landing-placeholder-4.jpg"
                }
            }
        ]
    },
    metaMaskFlow: {
        title: "Metamask",
        steps: [
            {
                subtitle: "Step One",
                title: "Open The Game",
                textHTML: "Head over to <a href='https://play.skyweaver.net' target='_blank' rel='noopener noreferrer'>play.skyweaver.net</a> on your browser to enter the world of Sky",
                video: {
                    url: "https://s3.amazonaws.com/skyweaver.net/video/get-started-metamask/1.mp4"
                }
            },
            {
                subtitle: "Step Two",
                title: "Link your wallet",
                textHTML: null,
                video: {
                    url: "https://s3.amazonaws.com/skyweaver.net/video/get-started-metamask/2.mp4"
                }
            },
            {
                subtitle: "Step Three",
                title: "Account OnBoarding",
                textHTML: `Once logged in to your Metamask wallet, you will be asked to connect to Skyweaver. Simply hit the Continue button to link your wallet to the game.`,
                video: {
                    url: "https://s3.amazonaws.com/skyweaver.net/video/get-started-metamask/3.mp4"
                }
            },
            {
                subtitle: "All Done",
                title: "Welcome To Sky",
                textHTML: `Welcome to Sky! You’re now ready to play! Learn the game in tutorials, battle against other players in Ranked matches, enter Conquests to win Gold NFT cards, build custom decks, browse the card library, and collect and trade in the Market!
`,
                video: {
                    url: "https://s3.amazonaws.com/skyweaver.net/video/get-started-metamask/4.mp4"
                }
            }
        ]
    },
    existingUserFlow: {
        subtitle: "FOR EXISTING PLAYERS",
        title: "ALREADY HAVE AN ACCOUNT?",
        text: "Just type in your username and the password associated with that Skyweaver account.",
        buttonText: "steps for existing players",
        img: {
            url: "/images/community/get-started/existing-users.jpg"
        },
        steps: [
            {
                subtitle: "Step One",
                title: "Open The Game",
                textHTML: "Head of to <a href='https://play.skyweaver.com' target='_blank' rel='noopener noreferrer'>play.skyweaver.com</a> on your browser to enter the world of Sky",
                video: {
                    url: "https://s3.amazonaws.com/skyweaver.net/video/get-started-existing/1.mp4"
                }
            },
            {
                subtitle: "Step Two",
                title: "Link your wallet",
                textHTML: null,
                video: {
                    url: "https://s3.amazonaws.com/skyweaver.net/video/get-started-existing/2.mp4"
                }
            },
            {
                subtitle: "Step Three",
                title: "Account OnBoarding",
                textHTML: `Once logged in to your Metamask wallet, you will be asked to connect to Skyweaver. Simply hit the Continue button to link your wallet to the game.`,
                video: {
                    url: "https://s3.amazonaws.com/skyweaver.net/video/get-started-existing/3.mp4"
                }
            },
            {
                subtitle: "All Done",
                title: "Welcome To Sky",
                textHTML: `That’s it! You are now in the Sky with a real wallet to own, collect and trade your cards`,
                video: {
                    url: "https://s3.amazonaws.com/skyweaver.net/video/get-started-existing/4.mp4"
                }
            }
        ]
    },
    gameModes: {
        title: "Game Modes",
        tradeSilverCardVideo: {
            url: `https://s3.amazonaws.com/skyweaver.net/video/get-started-usdc-updated/silver-conquest.mp4`
        },
        tradeDaiVideo: {
            url: `https://s3.amazonaws.com/skyweaver.net/video/get-started-usdc-updated/buy-usdc.mp4`
        },
        content: [
            {
                title: "Ranked",
                isLarge: true,
                textHTML: "Compete for Silver NFT cards. <br/> Battle against opponents to climb the leaderboard ranks and win tradable Silver cards every week. ",
                mobileText: "Compete for Silver NFT cards.",
                points: [
                    "Weekly Ranked Rewards",
                    "Win Tradable Silver NFT cards",
                    "Discovery or Constructed game type"
                ],
                img: {
                    url: "/images/community/get-started/ranked-game-mode.png",
                    mobile: {
                        url: "/images/community/get-started/RankedGMMobile.png"
                    }
                },
            },
            {
                title: "Conquest",
                isLarge: true,
                textHTML: "Win-streak PvP competition to win rare Gold NFT cards. Play until you reach three wins to earn the best rewards. Lose once, and your Conquest is over.",
                mobileText: "Win-streak PvP competition to win rare Gold cards.",
                points: [
                    "Win Rare, Limited Edition Tradable Gold NFT cards",
                    "Conquest Entry needed",
                    "Conquest tickets, Silver & Gold cards are stored in your wallet."
                ],
                img: {
                    url: "/images/community/get-started/conquest-game-mode.png",
                    mobile: {
                        url: "/images/community/get-started/ConquestGMMobile.png"
                    }
                },
            },
            {
                title: "Private",
                isLarge: false,
                textHTML: "Challenge a Friend. <br/> Invite them to a private game and earn the full amount of XP up to level 15.",
                img: {
                    url: "/images/community/get-started/private-game-mode.png"
                },
            },
            {
                title: "Practice",
                isLarge: false,
                textHTML: "Practice versus the Bot. <br/> Fight against our humble bot and earn XP until level 15.",
                img: {
                    url: "/images/community/get-started/practice-game-mode.png"
                },
            },
            {
                title: "Tutorial",
                isLarge: false,
                textHTML: "Learn How to Play. <br/> Help Ada on her journey as you learn the basics of Units, Mana, and Combat.",
                img: {
                    url: "/images/community/get-started/tutorial-game-mode.png"
                },
            }
        ]
    },
    allGameModesInclude: {
        subtitle: "For All Game Modes",
        title: "Select a Game Type",
        text: "For every game mode, you get to choose between two game types: Discovery and Constructed.",
        content: [
            {
                title: "Discovery",
                isLarge: true,
                textHTML: "Play a deck made of randomized cards from the Hero of your choice. <br/> <br/>If you haven’t constructed a deck yet or don’t feel like trying your hand at building your custom card deck, Discovery is a good game type to start with to quickly jump into a match.",
                subtitle: "Select a Hero for a deck made of randomized cards from the Hero's Prism(s)",
                mobileText: "Play a deck made of randomized cards from the Hero of your choice.",
                img: {
                    url: "/images/community/get-started/discovery-game-mode.png",
                    mobile: {
                        url: "/images/community/get-started/DiscoveryGMMobile.png"
                    }
                },
                secondaryImg: {
                    url: "/images/community/get-started/discovery-game-mode-alt.jpg"
                },
            },
            {
                title: "Constructed",
                isLarge: true,
                textHTML: "Play a deck that you’ve constructed. <br/><br/> Once you’ve unlocked enough cards to build a deck with, use our intuitive deckbuilder to customize your playstyle. <br/><br/>The deck leaderboard lets you find out which decks are performing best, their stats, card make-up, and more!",
                subtitle: "Choose the deck that you've constructred in your deck collection",
                mobileText: "Play a deck that you’ve constructed. ",
                img: {
                    url: "/images/community/get-started/constructed-game-mode.png",
                    mobile: {
                        url: "/images/community/get-started/ConstructedGMMobile.png"
                    }
                },
                secondaryImg: {
                    url: "/images/community/get-started/constructed-game-mode-alt.jpg"
                },
            }
        ]
    },
    marketplace: {
        title: "Marketplace",
        secondaryTitle: "First, there are two ways to win cards*",
        subtitle: "* Base cards with the purple frame are not tradable.",
        text: "Your Sequence Wallet is the key to enabling this in Skyweaver! That’s why you were asked to create your Sequence Wallet when you first got started so as you play and win tradable Silver and Gold cards, these cards can be automatically stored - securely - in your wallet.",
        cardImage: {
            url: "/images/community/get-started/card-types.png"
        },
        img: {
            url: "/images/community/get-started/marketplace-background.jpg"
        },
        content: [
            {
                title: "Ranked",
                text: "Win Silver NFT cards in weekly Ranked Rewards by securing a top spot on the leaderboards.",
                img: {
                    url: "/images/community/get-started/marketplace-ranked.png"
                },
                mobile_img: {
                    url: "/images/community/get-started/RankedMobile.png"
                }

            },
            {
                title: "Conquest",
                text: "The only way to win Gold NFT cards. The more Conquest matches you win, the better your reward!",
                img: {
                    url: "/images/community/get-started/marketplace-conquest.png"
                },
                mobile_img: {
                    url: "/images/community/get-started/ConquestMobile.png"
                }

            }
        ]
    },
    sequenceWallet: {
        // subtitle: "YOUR SEQUENCE WALLET STORES YOUR CARDS",
        title: "TRUE OWNERSHIP: PLAY TO OWN YOUR CARDS",
        textHTML: `Your Sequence Wallet securely stores your Skyweaver cards, and it grants you access to the Skyweaver Market where you can trade your cards with other players. That's why we asked you to create a Sequence Wallet when you first got started!<br/><br/>

        Skyweaver is a digital Trading Card Game with <em>actual</em> trading!
        `,
        carousel: [
            { img: { url: "/images/community/get-started/iPhone1.png" } },
            { img: { url: "/images/community/get-started/iphone2.png" } },
            { img: { url: "/images/community/get-started/iphone3.png" } },
            // { img: { url: "/images/community/get-started/iPhone 8 Plus - 3.png" } },
            // { img: { url: "/images/community/get-started/iPhone 8 Plus - 4.png" } },
            // { img: { url: "/images/community/get-started/iPhone 8 Plus - 5.png" } },
            // { img: { url: "/images/community/get-started/iPhone 8 Plus - 6.png" } },
            // { img: { url: "/images/community/get-started/iPhone 8 Plus - 7.png" } },
            // { img: { url: "/images/community/get-started/iPhone 8 Plus - 8.png" } },
            // { img: { url: "/images/community/get-started/iPhone 8 Plus - 9.png" } },
            // { img: { url: "/images/community/get-started/iPhone 8 Plus - 10.png" } },
        ]
    },
    yourAssets: {
        subtitle: "YOUR CARDS, YOUR CHOICE",
        title: "A TRADING CARD GAME WITH ACTUAL TRADING",
        text: "Silver and Gold NFT cards you win or buy are your property, and you decide what to do with them. You can trade them, gift to a friend, or keep collecting and playing. The choice is yours.",
        points: [
            "TRADE WITH PLAYERS", "BUY INDIVIDUAL CARDS", "SELL INSTANTLY"
        ],
        img: {
            url: "/images/community/get-started/card-list.jpg"
        }
    },
    tradeBenefits: {
        title: "PLAY, WIN, OWN, COLLECT & TRADE! ",
        secondaryTitle: "Connect your wallet and start playing Skyweaver!",
        text: "To get your beta access code, sign up to the beta waitlist here:",
        buttonText: "SIGN UP TO BETA WAITLIST",
        content: [
            {
                title: "PLAY & COMPETE ",
                text: "Battle your way to the top and hone your skills",
                img: {
                    url: "/images/community/get-started/play-win-1.jpg"
                }
            },
            {
                title: "OWN & COLLECT",
                text: "Store your tradable cards in your wallet",
                img: {
                    url: "/images/community/get-started/play-win-2.png"
                }
            },
            {
                title: "GIFT & TRADE ",
                text: "Use your wallet to gift cards and trade with others in the Market",
                img: {
                    url: "/images/community/get-started/play-win-3.jpg"
                }
            },
            {
                title: "BUILD CUSTOM DECKS ",
                text: "Intuitive deckbuilding for endless gameplay possibilities",
                img: {
                    url: "/images/community/get-started/play-win-4.jpg"
                }
            },
        ]
    }
}

export default data
